




import { Component, Vue } from 'vue-property-decorator'
import ListMenu from '@/components/ListMenu.vue'
import _ from 'underscore'

@Component({
  components: {
    'AppMenu': ListMenu
  }
})
export default class ReportIndex extends Vue {}
